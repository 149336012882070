import React from 'react';
import { FaTwitter, FaGithub, FaRocket, FaFacebook } from 'react-icons/fa';
import { StaticQuery, graphql } from 'gatsby';
import './style.scss';
import Emoji from './emoji';

const Footer = () => (
	<StaticQuery
		query={graphql`
			query SocialQuery {
				site {
					siteMetadata {
						gatsby
						bulma
						twitter
						github
					}
				}
			}
		`}
		render={data => (
			<footer className="footer center has-background-light">
				<div className="content has-text-centered">
					<p className="is-size-4">
						React Theme Bulma
					</p>
					<p className="is-size-4">
						Original author <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/amanhimself">Aman Mittal</a> 
					</p>
					<article className="media center">
						<span className="icon">
							<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/webappseed">
								<FaTwitter size="fa-2x" color="blue" />
							</a>
						</span>
						&nbsp;&nbsp;
						<span className="icon">
							<a target="_blank" rel="noopener noreferrer" href="https://github.com/react-theme/bulma">
								<FaGithub size="fa-2x" color="black" />
							</a>
						</span>
						&nbsp;&nbsp;
						<span className="icon">
							<a target="_blank" rel="noopener noreferrer" href="https://facebook.com/webappseed">
								<FaFacebook size="fa-2x" color="black" />
							</a>
						</span>
						&nbsp;&nbsp;
						<span className="icon">
							<a target="_blank" rel="noopener noreferrer" href="https://appseed.us">
								<FaRocket size="fa-2x" color="red" />
							</a>
						</span>
						&nbsp;
					</article>
					&nbsp;
					<p className="is-size-5">
						Enhanced and maintained 
						by{' '}
						<a href="https://appseed.us">AppSeed</a>
					</p>
				</div>
			</footer>
		)}
	/>
);

export default Footer;
